@use 'th-common/styles/index';

.text {
  line-height: 1;
}

.textWithEllipsis {
  @include index.ellipsis(auto);

  max-width: 100%;
  line-height: 1;
}
