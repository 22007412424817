@use 'th-common/styles/index';

.infoIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-on-surf-variant);

  &:hover {
    color: var(--color-on-surf);
  }
}
