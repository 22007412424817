@use 'th-common/styles/index';

.footer {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
  justify-content: flex-end;
  margin-top: -#{index.$spacing-sm};
  padding: index.$spacing-xxs 0;
  color: var(--color-on-surf-variant);
  text-align: right;
}
