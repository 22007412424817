@use 'th-common/styles/index';

.jobTypeTileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 6.5rem;
  padding: index.$padding-md;
  color: var(--color-on-surf-variant);
  background: var(--create-job-type-card-background, var(--color-surf));
  border-radius: index.$radius-md;

  &:hover,
  &_active {
    --create-job-type-card-background: var(--color-surf-container-high);

    box-shadow: inset 0 0 0 1px var(--color-primary);
    cursor: pointer;
  }
}

.title {
  color: var(--color-on-surf);
}
