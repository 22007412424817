@use 'th-common/styles/index';

.databaseDeviceTags {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
  width: 100%;
}

.tagWithIcon {
  display: flex;
  gap: index.$spacing-xxs;
  align-items: center;
}
