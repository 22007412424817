@use 'th-common/styles/index';

.modal {
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  gap: index.$spacing-sm;
  padding: index.$padding-sm;
}

.createJobWrapper {
  display: grid;
  grid-auto-rows: 11.75rem;
  grid-template-columns: repeat(3, 1fr);
  gap: index.$spacing-md;
  padding-right: 0.5rem;
  overflow: auto;

  @include index.breakpoint(index.$screen-large) {
    grid-template-columns: repeat(4, 1fr);
  }
}
