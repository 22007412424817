.printMetaDataContainer {
  position: relative;
  display: grid;
  height: 100%;

  &_noInteractions {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &_bottom {
    grid-template-rows: minmax(0, 30rem) auto;
    grid-template-columns: 1fr;
  }

  &_left {
    grid-template-rows: minmax(0, 30rem);
    grid-template-columns: auto 1fr;

    > *:first-child {
      order: 1;
    }
  }

  &_right {
    grid-template-rows: minmax(0, 30rem);
    grid-template-columns: 1fr auto;
  }

  &_top {
    grid-template-rows: auto minmax(0, 30rem);
    grid-template-columns: 1fr;

    > *:first-child {
      order: 1;
    }
  }
}
