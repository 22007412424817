@use 'th-common/styles/index';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: index.$spacing-md;
  align-items: center;
  justify-content: center;
  max-width: 43.75rem;
  color: var(--color-on-surf);
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500 !important;
  font-size: 13rem !important;
}

.subtitle {
  font-weight: 500 !important;
}

.description {
  color: var(--color-on-surf-variant) !important;
}
