@use 'th-common/styles/index';

.grid {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: index.$spacing-sm;
}

.mapGrid {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: index.$spacing-sm;
  height: 100%;
}

.buttons {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}

.mapControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapLegend {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
}

.mapLegendColor {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--color-warning);
  border-radius: index.$radius-xs;
}
