.printModal {
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  gap: var(--spacing-sm);
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 50rem;
  height: fit-content;
  max-height: 100%;
  margin: 0 auto;
  padding-top: var(--padding-xs) !important;
}

.modalActions {
  display: flex;
  gap: var(--spacing-sm);
  align-items: center;
  justify-content: flex-end;
}

.modalContent {
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  gap: var(--spacing-sm);
}

.checkboxGroup {
  flex-direction: row !important;
}

.positionBtn {
  box-sizing: content-box !important;
  width: 3rem;
}
